export default [
  {
    componentNameCn: "头像",
    componentNameEn: "Avatar",
    componentNote: "备注文字",
    events: [
      {
        eventName: "头像_调用头像",
        eventIdentifier: "avatar_call",
        eventDesc: "avatar_call",
        eventTrigger: "组件被调用时",
        eventClient: {
          clientApp: "支持",
          clientH5: "支持",
          clientWeb: "不支持"
        },
        attributes: [
          {
            attrName: "头像显示类型",
            attrIdentifier: "avatar_type",
            attrValues: ["text", "image", "icon"],
            attrType: "string"
          },
          {
            attrName: "头像形状",
            attrIdentifier: "avatar_shape",
            attrValues: ["square", "round"],
            attrType: "string"
          }
        ]
      }
    ]
  }
]
