<template>
  <ul
    v-if="data && data.length"
    class="sidebar-items"
    :class="[
      data[0].hideMenuIcon ? 'sidebar-vuepress' : '',
    ]"
  >
    <template v-for="(item, index) in data">
      <SideBarItem
        v-if="headerLevel > item.level"
        :key="index"
        v-bind="{
          text: item.text,
          children: item.children,
          link: item.link,
          level: item.level,
          anchor: item.anchor,
          collapse: item.collapse,
          hideMenuIcon: item.hideMenuIcon,
          headerLevel: headerLevel,
          depth: item.depth
        }"
      />
    </template>
  </ul>
</template>

<script>
import SideBarItem from "@/components/SideBarItem/index.js"

export default {
  name: "SideBarItems",
  components: {
    SideBarItem
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    headerLevel: {
      type: [Number, String],
      default: 4
    }
  }
}
</script>

<style lang="scss"></style>
