import CodeCopy from "./code-copy.vue"
import Vue from "vue"

export default {
  updated() {
    // document.querySelectorAll('pre[class="markdown"]').forEach((el) => {
    document.querySelectorAll('div[class*="language-"] pre').forEach((el) => {
      if (el.classList.contains("code-copy-added")) return
      const ComponentClass = Vue.extend(CodeCopy)
      const instance = new ComponentClass()
      instance.code = el.innerText
      instance.$mount()
      el.classList.add("code-copy-added")
      el.style["position"] = "relative"
      el.appendChild(instance.$el)
    })
  }
}
