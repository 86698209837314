<template>
  <div
    class="dropdown-wrapper"
    :class="{ open }"
  >
    <button
      class="dropdown-title"
      type="button"
      :aria-label="dropdownAriaLabel"
      @click="handleDropdown(e)"
    >
      <span class="title" :style="{color: reverse ? '#FFF' : ''}">{{ item.text }}</span>
      <span class="arrow">
        <cube-icon name="cube-caret-down-outline" color="#ccc" width="16px" height="16px" />
      </span>

    </button>
    <button
      class="dropdown-title_mobile"
      type="button"
      :aria-label="dropdownAriaLabel"
      @click="setOpen(!open)"
    >
      <span class="title" :style="{color: reverse ? '#FFF' : ''}">{{ item.text }}</span>
      <cube-icon :name="open? 'cube-caret-down-outline':'cube-caret-right-outline'" color="#ccc" width="16px" height="16px" />
    </button>

    <DropdownTransition>
      <ul
        v-show="open"
        class="dropdown-items"
      >
        <li
          v-for="(subItem, index) in item.items"
          :key="subItem.link || index"
          class="dropdown-items__item"
        >
          <h4 v-if="subItem.type === 'links'">
            {{ subItem.text }}
          </h4>

          <ul
            v-if="subItem.type === 'links'"
            class="dropdown-subitem-wrapper"
          >
            <li
              v-for="childSubItem in subItem.items"
              :key="childSubItem.link"
              class="dropdown-subitem"
            >
              <NavLink
                :item="childSubItem"
                @focusout="
                  isLastItemOfArray(childSubItem, subItem.items) &&
                    isLastItemOfArray(subItem, item.items) &&
                    setOpen(false)
                "
              />
            </li>
          </ul>
          <NavLink
            v-else
            :item="subItem"
            @focusout="isLastItemOfArray(subItem, item.items) && setOpen(false)"
          />
        </li>
      </ul>
    </DropdownTransition>
  </div>
</template>

<script>
import NavLink from "@/components/NavLink/index"
import DropdownTransition from "@/components/DropdownTransition/index.js"
import last from "lodash/last"

export default {
  name: "DropdownLink",

  components: {
    NavLink,
    DropdownTransition
  },

  props: {
    item: {
      type: Object,
      required: true
    },
    reverse: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      open: false
    }
  },

  computed: {
    dropdownAriaLabel() {
      return this.item.ariaLabel || this.item.text
    }
  },

  watch: {
    $route() {
      this.open = false
    }
  },

  methods: {
    setOpen(value) {
      this.open = value
    },

    isLastItemOfArray(item, array) {
      return last(array) === item
    },

    /**
     * Open the dropdown when user tab and click from keyboard.
     *
     * Use event.detail to detect tab and click from keyboard. Ref: https://developer.mozilla.org/en-US/docs/Web/API/UIEvent/detail
     * The Tab + Click is UIEvent > KeyboardEvent, so the detail is 0.
     */
    handleDropdown(event) {
      const isTriggerByTab = event.detail === 0
      if (isTriggerByTab) this.setOpen(!this.open)
    }
  }
}
</script>

<style lang="scss">
@import './index.scss'
</style>

