<template>
  <div>
    <Home />
  </div>
</template>

<script>
import Home from "../components/Home/index.js"
export default {
  components: {
    Home
  }
}
</script>

<style lang="scss" scoped>
</style>
