/**
 * 遍历获取文档目录下的所有 vue, md 文件（含子目录），生成路由列表
 * 默认为项目根目录的 docs 文件夹
 */
import { makeId } from "../utils"
import frontMatter from "../../.cube/pagesData.json"
const { pagesData } = frontMatter

/**
 * 根据文件路径解析文件信息
 * @param {String} file 文件路径
 * @returns 文件信息
 * 例如 ./a/b/c.md => { md, c, a/b/c, /a/b/c.md }
 */
const parseFilePath = (file) => {
  const pathArr = file.split(/\/|\./).filter((x) => x)
  // 获取文件后缀（不含.）
  const fileExt = file.split(".").slice(-1)[0]
  // 获取文件名（不含后缀）
  const fileName = file.split("/").slice(-1)[0].replace(/\..*/, "")
  // 生成路由路径，一般当作嵌套路由，所以开头不含 /
  // 如果是index/readme的文件路径，修改为截取到文件夹名称的路由即可
  const arr = ["index", "readme"]
  const routePath = arr.includes(pathArr[pathArr.length - 2])
    ? `${pathArr.slice(0, -2).join("/")}`
    : `${pathArr.slice(0, -1).join("/")}`
  // 文件相对路径，和 /docs 拼接后就是文件在项目内的路径
  const filePath = `/${pathArr.slice(0, -1).join("/")}.${fileExt}`

  return {
    fileExt,
    fileName,
    routePath,
    filePath
  }
}

const docsFiles = require.context(`/docs`, true, /\.(vue|md)$/).keys()

// 查找当前路径在pagesData的索引
const getIndex = (path) => {
  return pagesData.findIndex((page) => {
    return page.path === "/docs/" + path || page.path === "docs/" + path
    // const pathArr = page.path.split(/\/|\./).filter((x) => x)
    // const arr = ["index", "readme"]
    // const filepath = arr.includes(pathArr[pathArr.length - 1])
    //   ? `${pathArr.slice(0, -1).join("/")}`
    //   : `${pathArr.join("/")}`
    // return filepath === "/docs/" + path || filepath === "docs/" + path
  })
}

const docsArr = docsFiles.map((item) => {
  return item.slice(0, -5)
})

// 找出数组中的重复元素并去重
const findDuplicates = (arr) => {
  return arr.filter((item, index) => arr.indexOf(item) !== index)
}

const uniqueDuplicates = [...new Set(findDuplicates(docsArr))]

// 使用 map() 方法将重复元素转换为对象结构
const resultFiles = {}
// 重定向的数组，后续需要拼接到路由的数组中
const redirectList = []
uniqueDuplicates.forEach((item) => {
  const arr = [item + "-a.md", item + "-b.md", item + "-c.md"]
  let redirect = "" // 重定向的值
  let title = ""
  let description = ""
  const tabsList = []
  arr.forEach((element) => {
    const x = parseFilePath(element)
    const index = getIndex(x.routePath)
    const meta = index === -1 ? {} : pagesData[index].frontMatter
    // tab的顺位
    const tabOrder = meta.tabOrder ? meta.tabOrder : null
    // tab的名称
    const tabName = meta.tabName ? meta.tabName : null
    // 当前路由存在
    if (tabOrder) {
      tabsList[tabOrder - 1] = {
        title: tabName,
        name: tabOrder,
        path: x.routePath
      }
      if (tabOrder === 1) {
        redirect = x.routePath
        title = meta.title
        description = meta.description
      }
    }
    resultFiles[element] = {
      path: x.routePath,
      name: `${x.fileName}${makeId(6)}`,
      component: (resolve) => require([`../../docs${x.filePath}`], resolve),
      meta: meta
    }
  })
  arr.forEach(element => {
    resultFiles[element].meta.tabsList = tabsList
    // 如果有需要重定向的就把当前的对象加入到重定向的数组中
    if (element.includes(redirect)) {
      const redirectObj = JSON.parse(JSON.stringify(resultFiles[element]))
      const pathArr = redirectObj.path.split("/")
      pathArr.pop()
      redirectObj.path = pathArr.join("/")
      redirectObj.redirect = redirect
      redirectList.push(redirectObj)
    } else {
      resultFiles[element].meta.title = resultFiles[element].meta.title || title
      resultFiles[element].meta.description = resultFiles[element].meta.description || description
    }
  })
})

// const demoFiles = require.context(`/demo`, true, /\.(vue|md)$/).keys()

const docsRoutes = docsFiles
  .map((item) => {
    // 如果存在子级路由 遍历子级的路由，并且获取他们的meta的配置
    if (resultFiles[item]) {
      return resultFiles[item]
    } else {
      const x = parseFilePath(item)
      const index = getIndex(x.routePath)
      return {
        path: encodeURI(x.routePath),
        name: `${x.fileName}${makeId(6)}`,
        component: (resolve) => require([`../../docs${x.filePath}`], resolve),
        meta: index === -1 ? {} : pagesData[index].frontMatter
      }
    }
  })
  .concat(redirectList)
export default docsRoutes
