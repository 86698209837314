<template>
  <div>
    <div class="cube-mobile-simulator">
      <iframe ref="iframe" frameborder="0" :src="demoUrl" />
    </div>
  </div>
</template>
<script>
import { eventLisener } from "../../utils/index"

export default {
  name: "MobileSimulator",
  props: {
    demoUrl: {
      type: String,
      default: ""
    }
  },
  beforeRouteUpdate(to, from, next) {
    console.log("to", to)
    // this.$refs.iframe.contentWindow.scrollTo(0, 0)
    const toPath = to.path
    const fromPath = from.path
    if (toPath !== fromPath) {
      this.bodyContent.scrollTop = 0
    }
    next()
    this.$nextTick(() => {
      this.goAnchor()
      if (toPath !== fromPath) {
        this.renderAnchorHref()
      }
    })
    eventLisener("asyncComponentLoaded", () => {
      this.$nextTick(() => {
        this.goAnchor()
        if (toPath !== fromPath) {
          this.renderAnchorHref()
        }
      })
    })
  },
  data() {
    return {
      bodyContent: null
    }
  },
  computed: {
  },
  watch: {
  },
  mounted() {
    this.bodyContent = document.querySelector(".content-container")
    this.renderAnchorHref()
    this.goAnchor()
  },
  methods: {
    renderAnchorHref() {
      const anchors = document.querySelectorAll("h2 a,h3 a,h4 a,h5 a")
      const basePath = location.href.split("#").splice(0, 2).join("#")

      Array.prototype.slice.call(anchors).forEach((a) => {
        const href = a.getAttribute("href")
        a.href = href.indexOf(basePath) > -1 ? href : basePath + href
      })
    },
    goAnchor() {
      const locationArr = location.href.match(/#/g)
      if (locationArr && locationArr.length > 1) {
        const anchor = location.href.match(/#[^#]+$/g)
        if (!anchor) return
        const elm = document.querySelector(anchor[0])
        if (!elm) return

        this.$nextTick(() => {
          this.bodyContent.scrollTop = elm.offsetTop
        })
      }
    }
  }
}
</script>
<style lang="scss">
@import './index.scss';
</style>
