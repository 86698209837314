<template>
  <header class="navbar" :class="{ reverse: reverse }">
    <SidebarButton @toggle-sidebar="$emit('toggle-sidebar')" />

    <RouterLink :to="$localePath" class="navbar__logo">
      <img v-if="reverse" src="/images/logo-reverse.png">
      <img v-else src="/images/logo.png">
      <span>{{ $site.title }}</span>
    </RouterLink>

    <div class="navbar__right">
      <NavSearch v-if="!hideSearch" />
      <NavLinks :nav-menus="navMenus" />
      <VersionMenu v-if="!hideSearch" />
      <ToggleThemeButton />
    </div>
  </header>
</template>

<script>
import NavSearch from "@/components/NavSearch/index.js"
import VersionMenu from "@/components/VersionMenu/index.js"
import ToggleThemeButton from "@/components/ToggleThemeButton/index.js"

export default {
  name: "Navbar",
  components: {
    NavSearch,
    VersionMenu,
    ToggleThemeButton
  },

  props: {
    navMenus: {
      type: Array,
      default: () => []
    },
    hideSearch: {
      type: Boolean,
      default: false
    },
    reverse: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {}
  },

  computed: {},

  mounted() {}
}
</script>

<style lang="scss">
@import "./index.scss";
</style>
