var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{class:[`sidebar-item-level${_vm.level}`]},[_c('a',{staticClass:"sidebar-item",class:{ active: _vm.link == decodeURIComponent(_vm.$route.path) },on:{"click":_vm.goClick}},[_c('span',{staticClass:"sidebar-item__title"},[_vm._v(_vm._s(_vm.text))]),(
        !_vm.hideMenuIcon &&
          ((_vm.children && _vm.children.length) || _vm.anchor) &&
          _vm.depth > 1
      )?_c('cube-icon',{attrs:{"name":!_vm.collapse
          ? 'cube-chevron-up-mini-outline'
          : 'cube-chevron-down-mini-outline',"type":"png","width":"20px","height":"20px"}}):_vm._e()],1),(_vm.hideMenuIcon)?[(_vm.anchor && _vm.link === decodeURIComponent(_vm.$route.path))?_c('div',{staticClass:"sidebar-item-children"},[_c('Anchor',{attrs:{"data":_vm.anchor,"anchor-content":true,"anchor-depth":_vm.depth}})],1):(_vm.children && _vm.children.length)?_c('ul',{staticClass:"sidebar-item-children"},[_vm._l((_vm.children),function(item,index){return [(_vm.headerLevel > _vm.level)?_c('SideBarItem',_vm._b({key:index},'SideBarItem',{
            text: item.text,
            children: item.children,
            link: item.link,
            level: item.level,
            anchor: item.anchor,
            collapse: item.collapse,
            hideMenuIcon: _vm.hideMenuIcon,
            headerLevel: _vm.headerLevel,
            depth: item.depth ? item.depth : _vm.depth,
          },false)):_vm._e()]})],2):_vm._e()]:[(_vm.anchor && !_vm.collapse)?_c('div',{staticClass:"sidebar-item-children",style:({
        maxHeight: !_vm.collapse ? `${_vm.anchor.length * 34}px` : '0px',
      })},[_c('Anchor',{attrs:{"data":_vm.anchor,"anchor-content":true,"anchor-depth":_vm.depth}})],1):(_vm.children && _vm.children.length && !_vm.collapse)?_c('ul',{staticClass:"sidebar-item-children"},[_vm._l((_vm.children),function(item,index){return [(_vm.headerLevel > _vm.level)?_c('SideBarItem',_vm._b({key:index},'SideBarItem',{
            text: item.text,
            children: item.children,
            link: item.link,
            level: item.level,
            anchor: item.anchor,
            collapse: item.collapse,
            hideMenuIcon: _vm.hideMenuIcon,
            headerLevel: _vm.headerLevel,
            depth: item.depth ? item.depth : _vm.depth,
          },false)):_vm._e()]})],2):_vm._e()]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }