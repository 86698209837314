<template>
  <aside class="sidebar">
    <NavLinks :nav-menus="navMenus" />

    <slot name="top" />

    <template v-if="sidebar === 'auto'">
      <Anchor
        v-if="items.length"
        :data="items"
        :title="title"
        :anchor-depth="sidebarDepth"
        :anchor-content="true"
      />
    </template>
    <template v-else-if="sidebar === 'custom'">
      <SideBarItems
        v-if="items.length"
        :data="items"
        :header-level="headerLevel"
      />
    </template>
    <slot name="bottom" />
  </aside>
</template>

<script>
import NavLinks from "@/components/NavLinks/index.js"

export default {
  name: "Sidebar",

  components: { NavLinks },

  props: {
    // 文章标题
    title: {
      type: String,
      default: ""
    },
    items: {
      type: Array,
      default: () => []
    },
    navMenus: {
      type: Array,
      default: () => []
    },
    sidebar: {
      type: String,
      default: "auto"
    },
    sidebarDepth: {
      type: [String, Number],
      default: 6
    },
    headerLevel: {
      type: [String, Number],
      default: 4
    }
  }
}
</script>

<style lang="scss">
@import "./index.scss";
</style>
