<template>
  <div class="default-home">
    <header class="default-home__hero">
      <img
        v-if="data.heroImage"
        :src="data.heroImage"
        :alt="data.heroAlt || 'hero'"
      >

      <h1 v-if="data.heroText">
        {{ data.heroText || "Cube Docs" }}
      </h1>

      <p v-if="data.tagline" class="default-home__hero_desc">
        {{ data.tagline || "静态文档脚手架" }}
      </p>

      <p
        v-if="data.actions && data.actions.length"
        class="default-home__hero_action"
      >
        <span
          v-for="item in data.actions"
          :key="item.text"
          class="navbar__logo"
          :class="[`${item.type}`]"
        >
          <RouterLink
            v-if="!isExternal(item.link)"
            :to="item.link"
          >{{ item.text }}
          </RouterLink>
          <a v-else :href="item.link">{{ item.text }}</a>
        </span>
      </p>
    </header>

    <div
      v-if="data.features && data.features.length"
      class="default-home__features"
    >
      <div v-for="(feature, index) in data.features" :key="index">
        <h2>{{ feature.title }}</h2>
        <p>{{ feature.details }}</p>
      </div>
    </div>

    <div v-if="$site.copyright" class="default-home__footer">
      {{ $site.copyright }}
    </div>
  </div>
</template>

<script>
import data from "config/home"
import { isExternal } from "../../utils"

export default {
  name: "DefaultHome",
  data() {
    return {}
  },

  computed: {
    data() {
      return data
    }
  },
  methods: {
    isExternal
  }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
