<template>
  <li :class="[`sidebar-item-level${level}`]">
    <a
      class="sidebar-item"
      :class="{ active: link == decodeURIComponent($route.path) }"
      @click="goClick"
    ><span class="sidebar-item__title">{{ text }}</span>
      <cube-icon
        v-if="
          !hideMenuIcon &&
            ((children && children.length) || anchor) &&
            depth > 1
        "
        :name="
          !collapse
            ? 'cube-chevron-up-mini-outline'
            : 'cube-chevron-down-mini-outline'
        "
        type="png"
        width="20px"
        height="20px"
      />
    </a>
    <!-- 隐藏按钮的状态样式 -->
    <template v-if="hideMenuIcon">
      <div v-if="anchor && link === decodeURIComponent($route.path)" class="sidebar-item-children">
        <Anchor :data="anchor" :anchor-content="true" :anchor-depth="depth" />
      </div>
      <ul v-else-if="children && children.length" class="sidebar-item-children">
        <template v-for="(item, index) in children">
          <!-- 递归渲染 -->
          <SideBarItem
            v-if="headerLevel > level"
            :key="index"
            v-bind="{
              text: item.text,
              children: item.children,
              link: item.link,
              level: item.level,
              anchor: item.anchor,
              collapse: item.collapse,
              hideMenuIcon: hideMenuIcon,
              headerLevel: headerLevel,
              depth: item.depth ? item.depth : depth,
            }"
          />
        </template>
      </ul>
    </template>
    <!-- 显示按钮的状态样式 -->
    <template v-else>
      <div
        v-if="anchor && !collapse"
        class="sidebar-item-children"
        :style="{
          maxHeight: !collapse ? `${anchor.length * 34}px` : '0px',
        }"
      >
        <Anchor :data="anchor" :anchor-content="true" :anchor-depth="depth" />
      </div>
      <ul
        v-else-if="children && children.length && !collapse"
        class="sidebar-item-children"
      >
        <template v-for="(item, index) in children">
          <!-- 递归渲染 -->
          <SideBarItem
            v-if="headerLevel > level"
            :key="index"
            v-bind="{
              text: item.text,
              children: item.children,
              link: item.link,
              level: item.level,
              anchor: item.anchor,
              collapse: item.collapse,
              hideMenuIcon: hideMenuIcon,
              headerLevel: headerLevel,
              depth: item.depth ? item.depth : depth,
            }"
          />
        </template>
      </ul>
    </template>
  </li>
</template>

<script>
import { ensureExt } from "@/utils"
export default {
  name: "SideBarItem",
  props: {
    text: {
      type: String,
      required: true
    },
    level: {
      type: [String, Number],
      default: null
    },
    link: {
      type: String,
      default: ""
    },
    children: {
      type: Array,
      default: () => []
    },
    anchor: {
      type: Array,
      default: () => null
    },
    headerLevel: {
      type: [Number, String],
      default: 4
    },
    collapse: {
      type: Boolean,
      default: true
    },
    hideMenuIcon: {
      type: Boolean,
      default: true
    },
    // 控制折叠anchor部分的标题层级
    depth: {
      type: Number,
      default: 6
    }
  },
  methods: {
    goClick() {
      if (this.link && decodeURIComponent(this.$route.path) !== this.link) {
        const url = ensureExt(this.link)
        this.$router.push({ path: url })
      }
      this.collapse = !this.collapse
    }
  }
}
</script>
