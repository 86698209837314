<template>
  <div v-if="navMenus.length" class="navbar-links">
    <div
      v-for="(item, index) in navMenus"
      :key="index"
      class="navbar-links__item"
    >
      <DropdownLink v-if="item.type === 'links'" :item="item" />
      <NavLink v-else :item="item" />
    </div>
  </div>
</template>

<script>
export default {
  name: "NavLinks",

  props: {
    navMenus: {
      type: Array,
      default: () => []
    },
    reverse: {
      type: Boolean,
      default: false
    }
  }
}
</script>
