<template>
  <div style="margin: 20px 50px">
    <h1 class="markdown">埋点清单</h1>
    <cubeAgGrid
      v-if="heightinif"
      child-row-key="attrIdentifier"
      children-list-key="details"
      is-subtable
      row-key="eventIdentifier"
      :is-size-columns-to-fit="true"
      :paginationinif="false"
      :heightinif="heightinif"
      :column-defs="columnDefs"
      :child-column-defs="childColumnDefs"
      :row-data="rowData"
      :load-detail="loadDetail"
    />
  </div>
</template>

<script>
import eventList from "./mobile-event-list"
export default {
  data() {
    return {
      columnDefs: [
        {
          headerName: "组件名称",
          field: "componentName",
          marryChildren: true,
          children: [
            {
              headerName: "中文名称",
              field: "componentNameCn",
              minWidth: 100,
              pinned: "left"
            },
            {
              field: "componentNameEn",
              headerName: "英文名称",
              minWidth: 100,
              pinned: "left"
            }
          ]
        },
        {
          headerName: "事件列表",
          field: "events",
          marryChildren: true,
          children: [
            {
              headerName: "事件名称",
              field: "eventName",
              pinned: "left"
            },
            {
              field: "eventIdentifier",
              headerName: "事件标识符"
            },
            {
              field: "eventTrigger",
              headerName: "埋点触发时机"
            }
          ]
        },
        {
          headerName: "客户端支持程度",
          field: "clientSupportLevel",
          marryChildren: true,
          children: [
            {
              headerName: "uni-app App",
              field: "clientApp",
              cellRenderer: ({ data: { eventClient }}) => {
                return `<span>${eventClient.clientApp}</span>`
              }
            },
            {
              headerName: "uni-app H5",
              field: "clientH5",
              cellRenderer: ({ data: { eventClient }}) => {
                return `<span>${eventClient.clientH5}</span>`
              }
            },
            {
              headerName: "Web",
              field: "clientWeb",
              cellRenderer: ({ data: { eventClient }}) => {
                return `<span>${eventClient.clientWeb}</span>`
              }
            }
          ]
        },
        {
          headerName: "备注",
          field: "componentNote",
          marryChildren: true,
          headerClass: "c-header_parent",
          children: [
            {
              headerName: "",
              field: "componentNote",
              minWidth: 100,
              headerClass: "c-header_child"
            }
          ]
        }
      ],
      childColumnDefs: [
        {
          headerName: "属性名称",
          field: "attrName"
        },
        {
          field: "attrIdentifier",
          headerName: "属性标识符"
        },
        {
          field: "attrType",
          headerName: "属性类型"
        },
        {
          field: "attrValues",
          headerName: "属性值"
        }
      ],
      rowData: [],
      heightinif: 0
    }
  },
  created() {
    const data = []
    eventList.forEach((item) => {
      let templateObj = {}
      templateObj = { ...templateObj, ...item }
      item.events.forEach((eventItem) => {
        templateObj = { ...templateObj, ...eventItem }
        delete templateObj.events
        data.push(templateObj)
      })
    })
    this.rowData = data
  },
  mounted() {
    // 视图高度减去导航栏和h1标题高度和margin
    this.heightinif = document.body.clientHeight - 58 - 112 - 40
  },
  methods: {
    loadDetail(data) {
      return data.attributes
    }
  }
}
</script>

<style lang="scss" scoped></style>
