export const versionConfig = [
  {
    text: "1.2.3",
    link: "https://web.cube.cndinfo.com/1.2.3/",
    items: {
      cube: "1.0.2",
      horizon: "1.0.7"
    }
  },
  {
    text: "1.2.4",
    items: {
      cube: "1.0.2",
      horizon: "1.0.8"
    }
  },
  {
    text: "1.2.5",
    items: {
      cube: "1.0.3",
      horizon: "1.0.9"
    }
  },
  {
    text: "2.2.3",
    items: {
      cube: "1.0.4",
      horizon: "1.1.0"
    }
  }
]
