<template>
  <div class="docs-custom-home">
    <h1>自定义首页</h1>
  </div>
</template>

<script>
export default {
  name: "Home"
}
</script>

<style lang="scss" scoped></style>
