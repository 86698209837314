<template>
  <div class="base-usage">
    <div class="btn">
      <div class="btn-main">
        <div class="main-head">
          <span />
        </div>
        <div class="main-content">
          <slot name="source" :config="configData" />
        </div>
        <div
          ref="control"
          class="main-foot"
          :class="{ 'is-fixed': fixedControl }"
        >
          <transition name="arrow-slide">
            <code-copy :code="copyCode" type="icon" />
          </transition>
          <transition name="text-slide">
            <div
              class="icon-open"
              :style="{ backgroundColor: iconBgColor }"
              @click="isExpanded = !isExpanded"
              @mouseleave="iconColor = isExpanded ? '#409eff':'#ccc'"
              @mouseenter="iconColor = '#409eff'"
            >
              <cube-tooltip :content="isExpanded ? '隐藏代码' : '显示代码'" placement="top">
                <cube-icon name="cube-expand-left-and-right-outline" :color="iconColor" width="20px" height="20px" />
              </cube-tooltip>
            </div>
          </transition>
        </div>
      </div>
      <div class="btn-config">
        <div class="title">
          <span>配置</span>
        </div>
        <div class="config-content">
          <ul class="checks">
            <li
              v-for="item in booleanList"
              :key="item.name"
              class="checks-item"
            >
              <span class="item-name">{{ item.name }}</span>
              <cube-switch
                v-model="item.defaultValue"
                @change="handleChange(item.name, item.defaultValue)"
              />
            </li>
          </ul>
          <ul class="options checks">
            <li v-for="item in enumList" :key="item.name" class="checks-item">
              <span class="item-name">{{ item.name }}</span>
              <cube-select
                v-model="item.defaultValue"
                placeholder="请选择"
                @change="handleChange(item.name, item.defaultValue)"
              >
                <cube-option
                  v-for="child in item.options"
                  :key="child.value"
                  :label="child.label"
                  :value="child.value"
                />
              </cube-select>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div ref="meta" class="meta">
      <div class="highlight">
        <pre
          class="markdown"
        ><code class="html markdown" v-html="hljsCode" /></pre>
      </div>
    </div>
  </div>
</template>

<script>
import CodeCopy from "@/components/CodeCopy/index.js"
const Prism = require("prismjs")
export default {
  name: "BaseUsage",
  components: {
    CodeCopy
  },
  props: {
    config: {
      type: Array,
      default() {
        return []
      }
    },
    code: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      configData: [],
      hasChange: false,
      isExpanded: false,
      fixedControl: false,
      copySuccess: false,
      copyCode: "", // 待复制的code
      hljsCode: "",
      booleanList: [],
      enumList: [],
      iconColor: "#ccc"
    }
  },
  computed: {
    iconBgColor() {
      return this.isExpanded ? "#f0f6ff" : ""
    },

    codeArea() {
      return this.$el.getElementsByClassName("meta")[0]
    },

    codeAreaHeight() {
      console.log(
        this.$el.getElementsByClassName("highlight")[0].scrollHeight,
        "highlight"
      )
      console.log(
        this.$el.getElementsByClassName("highlight")[0].clientHeight,
        "clientHeight"
      )
      return this.$el.getElementsByClassName("highlight")[0].clientHeight
    }
  },
  watch: {
    isExpanded(val) {
      if (this.isExpanded) {
        this.iconColor = "#409eff"
      } else {
        this.iconColor = "#ccc"
      }
      if (!this.hasChange) {
        this.hasChange = true
      }
      this.codeArea.style.height = val ? `${this.codeAreaHeight + 1}px` : "0"
      if (!val) {
        this.fixedControl = false
        this.$refs.control.style.left = "0"
        return
      }
    },
    copyCode(val) {
      this.hljsCode = Prism.highlight(val, Prism.languages.html)
    }
  },
  created() {
    this.copyCode = this.code
    this.renderConfig(this.config)
  },
  mounted() {
    this.setHljsCode()
  },
  methods: {
    setHljsCode() {
      let checkStr = "" // 选中的值
      Object.keys(this.configData).map((key) => {
        if (typeof this.configData[key] === "boolean") {
          checkStr = checkStr + `:${key}="${this.configData[key]}" `
        } else {
          checkStr = checkStr + `${key}="${this.configData[key]}" `
        }
      })
      this.copyCode = `<template>
  ${this.code.replace(/v-bind="config"/g, checkStr)}</template>`
    },
    handleChange(name, val) {
      this.$set(this.configData, name, val)
      this.setHljsCode()
    },
    // 获得配置的数据
    renderConfig(configList = []) {
      this.booleanList = []
      this.enumList = []
      this.configData = {}
      configList.forEach((item) => {
        this.configData[item.name] = item.defaultValue
        if (/boolean/i.test(item.type)) {
          this.booleanList.push(item)
        }
        if (/enum/i.test(item.type)) {
          this.enumList.push(item)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
