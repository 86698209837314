<template>
  <div class="anchor">
    <div v-if="anchorContent" class="anchor-menu">
      <div v-if="title" class="anchor-menu__item">
        <span class="anchor-menu__item__h1">{{ title }}</span>
      </div>
      <div
        v-for="item in data"
        v-show="item.level[1] <= anchorDepth"
        :key="item.id"
        class="anchor-menu__item"
        :class="activeStep === item.id ? 'active' : ''"
        :style="[customStyle]"
      >
        <!-- <span :class="`anchor-menu__item__${item.level}`">{{ item.text }}</span> -->
        <a
          :href="`#${item.id}`"
          :class="[
            `anchor-menu__item__${item.level}`,
            activeStep === item.id ? 'active' : '',
          ]"
          @click.prevent="jump(item.id)"
        >{{ item.text }}</a>
      </div>
    </div>
  </div>
</template>

<script>
const _ = require("lodash")

export default {
  name: "Anchor",
  props: {
    data: {
      type: Array,
      default: () => []
    },
    anchorContent: {
      type: Boolean,
      default: false
    },
    // 文章标题
    title: {
      type: String,
      default: ""
    },
    anchorDepth: {
      type: [String, Number],
      default: 6
    },
    customStyle: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      activeStep: "", // 默认选中的锚点的key值
      offsetTop: 0,
      observer: null,
      clientHeight: 0 // 屏幕高度
    }
  },
  computed: {
    hash() {
      return this.$route.params.hash?.toLowerCase()
    }
  },
  watch: {
    hash() {
      if (this.hash) {
        this.scrollView(this.hash)
      }
    },
    $route(to, from) {
      // 当前路由不触发
      if (to.path === from.path) return
      // 是搜索框跳转的带锚点的不触发
      if (this.hash) return
      // 兼容chrome
      document.body.scrollTop = 0
      // 兼容firefox
      document.documentElement.scrollTop = 0
      // 兼容safari
      window.pageYOffset = 0
    }
  },
  mounted() {
    const id = decodeURIComponent(location.hash)
    if (id) {
      this.activeStep = id.substring(1).toLowerCase()
      this.scrollView(id.substring(1).toLowerCase())
    }
    this.clientHeight = document.body.clientHeight
    window.addEventListener("scroll", _.debounce(this.onScroll, 0, false))
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scroll)
  },
  methods: {
    onScroll() {
      let index = null
      // 获取最后一个小于150的dom对象
      this.data.forEach((item, i) => {
        const scrollItem = document.getElementById(item.id) // 锚点对应的模块
        let lastTop = 0
        if (i > 0 && document.getElementById(this.data[i - 1].id)) {
          lastTop = document.getElementById(this.data[i - 1].id).getBoundingClientRect().top
        }

        const flag =
         scrollItem && scrollItem.getBoundingClientRect().top > 0 &&
          scrollItem.getBoundingClientRect().top <
            (i === this.data.length - 1 && lastTop <= 0 ? this.clientHeight : 150)
        if (flag) index = i
      })
      if (typeof index === "number") {
        if (this.activeStep === this.data[index].id) return
        this.activeStep = this.data[index].id
        this.$router.replace({
          hash: this.activeStep
        })
      }
    },
    scrollView(id) {
      const scrollItem = document.getElementById(id)
      scrollItem?.scrollIntoView({
        block: "start",
        behavior: "smooth",
        inline: "start"
      })
    },
    jump(id) {
      this.scrollView(id)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
