<template>
  <div class="bottom-nav" :style="{paddingLeft: isSidebar ? '' : '0px'}">
    <cube-bottom-nav
      logo="https://front-end-huawei-cdn.devops.cndinfo.com/static/images/cndinfo-logo.png"
      logo-width="200px"
      logo-height="28px"
    >
      <template slot="default">
        <div class="bottom-nav__default">
          <cube-bottom-nav-item :config="config" />
          <cube-bottom-nav-item :config="config1" />
        </div>
      </template>
      <template slot="bottom">
        <div class="bottom-nav__bottom">
          版权所有 © 2023 厦门建发信息技术有限公司
        </div>
      </template>
    </cube-bottom-nav>
  </div>
</template>
<script>
export default {
  props: {
    isSidebar: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      config: {
        label: "相关资源",
        children: [
          {
            label: "Cube 前端开发文档",
            url: "https://cube.cndinfo.com/"
          },
          {
            label: "Cube Design Mobile",
            url: "https://mobile.cube.cndinfo.com/"
          },
          {
            label: "Otter - 微服务开发平台",
            url: "https://otter.devops.cndinfo.com/"
          }
        ]
      },
      config1: {
        label: "关于",
        children: [
          {
            label: "建发信息",
            url: "https://www.cndinfo.com/"
          },
          {
            label: "建发股份",
            url: "https://www.chinacnd.com/"
          },
          {
            label: "建发集团",
            url: "https://www.chinacdc.com/"
          }
        ]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
