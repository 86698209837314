export default {
  heroImage: "/images/hero.png",
  heroText: "建发信息前端",
  tagline: "开发规范、指南及 SDK API",
  actions: [
    {
      text: "开发指南",
      link: "/docs/tutorial/develop/micro-frontends",
      type: "primary"
    },
    {
      text: "AMS",
      link: "https://ams.cube.cndinfo.com/",
      type: "secondary"
    }
  ],
  features: [
    {
      title: "微前端",
      details: "基于乾坤开发的微前端框架"
    },
    {
      title: "AMS",
      details: "通用 APP 管理平台"
    },
    {
      title: "手册与指南",
      details: "提供 PC、移动端开发指导"
    }
  ]
}
