<template>
  <div class="theme-container" :class="pageClasses">
    <Navbar :nav-menus="navMenus" @toggle-sidebar="toggleSidebar" />
    <div class="sidebar-mask" @click="toggleSidebar(false)" />
    <Sidebar
      :title="markdownTitle"
      :items="sideMenus"
      :sidebar="sidebar"
      :sidebar-depth="sidebarDepth"
      :nav-menus="navMenus"
    />
    <!-- <div v-if="hasSideBar" class="sidebar">
      <SideBarItems :data="sideMenus" />
    </div> -->
    <main
      class="page"
      :style="{ paddingRight: $route.path.indexOf('/docs') === 0 ? '' : '0px' }"
      :class="{ 'is-changelog': isChangelog, 'is-component': isComponent }"
    >
      <div
        v-if="$route.meta.title"
        class="markdown-container theme-default-content"
      >
        <h1 v-if="$route.meta.title" class="markdown">
          {{ $route.meta.title }}
        </h1>
        <p v-if="$route.meta.description" class="markdown">
          {{ $route.meta.description }}
        </p>
        <div v-if="$route.meta.tabsList" style="width: 300px">
          <cube-tabs
            v-model="tabsValue"
            header-type="default"
            stretch
            @tab-click="handleTabsEdit"
          >
            <cube-tab-pane
              v-for="item in $route.meta.tabsList"
              :key="item.name"
              :label="item.title"
              :name="item.name"
            />
          </cube-tabs>
        </div>
      </div>
      <router-view />
    </main>
    <!-- <BottomNav v-if="$route.path.indexOf('/docs') === 0" :is-sidebar="sideMenus.length" /> -->
    <template>
      <Anchor
        v-if="anchorData.length && !isComponent && !this.$site.showSimulator"
        :data="anchorData"
        :anchor-content="anchorContent"
        :anchor-depth="5"
      />
      <MobileSimulator
        v-if="isComponent && this.$site.showSimulator"
        :demo-url="demoUrl"
      />
    </template>
  </div>
</template>

<script>
import Anchor from "@/components/Anchor/index.js"
import MobileSimulator from "@/components/MobileSimulator/index.js"
import Navbar from "@/components/Navbar/index.js"
import { sidebarConfig } from "config/sidebar"
import { navbarConfig } from "config/navbar"
import { matchSideBar, resolveNavLinks } from "../utils"
import copyMixin from "@/components/CodeCopy/copy-mixin"
import { capitalizeFirstLetter } from "../../src/utils"

export default {
  name: "Layout",
  components: {
    Navbar,
    Anchor,
    MobileSimulator
  },
  mixins: [copyMixin],
  data() {
    return {
      tabsValue: 1,
      sideMenus: [],
      navMenus: [],
      isSidebarOpen: false,
      markdownTitle: "", // 文章标题
      anchorData: [], // 锚点目录的数组
      anchorContent: false, // 是否展示锚点，true是展示，false是不展示
      sidebar: "auto",
      sidebarDepth: 4,
      demoUrl: ""
    }
  },
  computed: {
    pageClasses() {
      return [
        {
          // "no-navbar": !this.shouldShowNavbar,
          "sidebar-open": this.isSidebarOpen,
          "no-sidebar": !this.hasSideBar
        }
      ]
    },
    hasSideBar() {
      return this.sideMenus && this.sideMenus.length
    },
    isChangelog() {
      return this.$route.path.includes("/developing/changelog")
    },
    isComponent() {
      return this.$route.path.includes("/docs/components/")
    }
  },
  watch: {
    $route(to, from) {
      if (to.path === from.path) return
      // 建立一个宏任务 进入事件执行的最后阶段，方便获取最新的dom对象
      setTimeout(() => {
        this.init()
        this.getAnchorData()
        this.sideMenus =
          this.sidebar === "auto"
            ? this.anchorData
            : matchSideBar(decodeURIComponent(this.$route.path), sidebarConfig)
      })
    }
  },
  created() {
    this.init()
  },
  mounted() {
    this.$router.afterEach(() => {
      this.isSidebarOpen = false
      this.$nextTick(() => {
        this.changeTitle()
      })
    })
    this.changeTitle()
    this.getAnchorData()
    this.sideMenus =
      this.sidebar === "auto"
        ? this.anchorData
        : matchSideBar(decodeURIComponent(this.$route.path), sidebarConfig)
    this.navMenus = resolveNavLinks(navbarConfig)
  },
  methods: {
    getDemoLink() {
      let compName = this.$route.path.split("/").pop()
      compName = compName
        .toLowerCase()
        .replace(/(-)[a-z]/g, (L) => L.toUpperCase())
        .replace(new RegExp("-", "g"), "")
      const linkSuffix = `${capitalizeFirstLetter(
        compName
      )}/${capitalizeFirstLetter(compName)}`
      const demoUrl = `https://h5.mobile.cube.cndinfo.com/pages/components/${linkSuffix}`
      this.demoUrl = demoUrl
      console.log("demoUrl", this.demoUrl)
    },
    changeTitle() {
      // 获取 markdown 标题
      const title = document.querySelectorAll("h1.markdown")[0]
      this.markdownTitle = title ? title.innerText : ""
      // 使用文章或者站点默认标题
      document.title = this.markdownTitle || this.$site.title
    },
    handleTabsEdit(targetName) {
      this.$router.push({
        path: `/docs/${this.$route.meta.tabsList[targetName.name - 1].path}`
      })
    },
    init() {
      this.sidebar = this.$route.meta.sidebar || "auto"
      this.sidebarDepth = this.$route.meta.sidebarDepth || 4
      this.anchorContent = this.$route.meta.anchorContent || false
      this.tabsValue = this.$route.meta.tabOrder || null
      if (this.isComponent && this.$site.showSimulator) {
        this.getDemoLink()
      }
    },
    getAnchorData() {
      this.anchorData = []
      const el = document.getElementsByClassName("header-anchor")
      for (var i = 0; i < el.length; i++) {
        this.anchorData.push({
          level: el[i].parentElement.tagName.toLowerCase(),
          id: el[i].parentElement.id,
          text: el[i].parentElement.innerText.slice(2)
        })
      }
    },
    toggleSidebar(to) {
      console.log("toggle", to)
      this.isSidebarOpen = typeof to === "boolean" ? to : !this.isSidebarOpen
      this.$emit("toggle-sidebar", this.isSidebarOpen)
      console.log("isSidebarOpen", this.isSidebarOpen)
    }
  }
}
</script>
