export const sidebarConfig = {
  "/docs/ecosystem/analytics/": [
    {
      text: "项目规范",
      children: [
        "/docs/ecosystem/analytics/volcengine",
        "/docs/ecosystem/analytics/volcengine-sdk"
      ]
    }
  ],
  "/docs/tutorial/sharing/compatible": [
    {
      text: "兼容性问题",
      children: [
        "/docs/tutorial/sharing/compatible/pc",
        "/docs/tutorial/sharing/compatible/mobile"
      ]
    }
  ]
}
