import Vue from "vue"
import VueI18n from "vue-i18n"
import Cookies from "js-cookie"
import elementEnLocale from "element-ui/lib/locale/lang/en" // element-ui lang
import elementZhLocale from "element-ui/lib/locale/lang/zh-CN" // element-ui lang
import elementEsLocale from "element-ui/lib/locale/lang/es" // element-ui lang
const zhLocale = window.zhLocale
const enLocale = window.enLocale
Vue.use(VueI18n)

const messages = {
  en: {
    ...enLocale,
    ...elementEnLocale
  },
  zh_CN: {
    ...zhLocale,
    ...elementZhLocale
  },
  es: {
    ...elementEsLocale
  }
}
// 默认语言
const i18n = new VueI18n({
  locale: Cookies.get("language") || "zh_CN",
  messages
})

export default i18n
