<template>
  <RouterLink
    v-if="isInternal"
    class="nav-link"
    :to="link"
    :exact="exact"
    @focusout.native="focusoutAction"
  >
    {{ item.text }}
  </RouterLink>
  <a
    v-else
    :href="link"
    class="nav-link external"
    :target="target"
    :rel="rel"
    @focusout="focusoutAction"
  >
    {{ item.text }}
    <OutboundLink v-if="isBlankTarget" />
  </a>
</template>

<script>
import { isExternal, isMailto, isTel, ensureExt } from "../../utils"

export default {
  name: "NavLink",

  props: {
    item: {
      required: true,
      type: Object
    },
    reverse: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    link() {
      return this.item.link ? ensureExt(this.item.link) : ""
    },

    exact() {
      if (this.$site.locales) {
        return Object.keys(this.$site.locales).some(rootLink => rootLink === this.link)
      }
      return this.link === "/"
    },

    isNonHttpURI() {
      return isMailto(this.link) || isTel(this.link)
    },

    isBlankTarget() {
      return this.target === "_blank"
    },

    isInternal() {
      return !isExternal(this.link) && !this.isBlankTarget
    },

    target() {
      if (this.isNonHttpURI) {
        return null
      }
      if (this.item.target) {
        return this.item.target
      }
      return isExternal(this.link) ? "_blank" : ""
    },

    rel() {
      if (this.isNonHttpURI) {
        return null
      }
      if (this.item.rel === false) {
        return null
      }
      if (this.item.rel) {
        return this.item.rel
      }
      return this.isBlankTarget ? "noopener noreferrer" : null
    }
  },

  methods: {
    focusoutAction() {
      this.$emit("focusout")
    }
  }
}
</script>
