<template>
  <div v-if="type === 'icon'" class="code-copy-icon" @click="copyToClipboard">
    <cube-tooltip :content="copySuccess ? '代码已复制' : '复制代码'" placement="top">
      <cube-icon
        :class-name="copySuccess ? 'success' : ''"
        :name="copySuccess ? 'cube-tick-circle-filled' : 'cube-copy-outline'"
        :color="copySuccess ? '#52c41a' : ''"
        width="18px"
        height="18px"
      />
    </cube-tooltip>
  </div>
  <span
    v-else-if="type === 'text'"
    class="code-copy-btn"
    @click="copyToClipboard"
  >{{ copySuccess ? "代码已复制" : "复制代码" }}</span>
</template>

<script>
export default {
  props: {
    code: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "text"
    }
  },
  data() {
    return {
      copySuccess: false
    }
  },
  methods: {
    copyToClipboard(el) {
      this.setClipboard(this.code.trim() + "\n", this.setText)
    },
    setClipboard(code, cb) {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(code).then(cb, () => {})
      } else {
        const copyelement = document.createElement("textarea")
        document.body.appendChild(copyelement)
        copyelement.value = code
        copyelement.select()
        document.execCommand("Copy")
        copyelement.remove()
        cb()
      }
    },
    setText() {
      this.copySuccess = true

      setTimeout(() => {
        this.copySuccess = false
      }, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
