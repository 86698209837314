var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.data && _vm.data.length)?_c('ul',{staticClass:"sidebar-items",class:[
    _vm.data[0].hideMenuIcon ? 'sidebar-vuepress' : '',
  ]},[_vm._l((_vm.data),function(item,index){return [(_vm.headerLevel > item.level)?_c('SideBarItem',_vm._b({key:index},'SideBarItem',{
        text: item.text,
        children: item.children,
        link: item.link,
        level: item.level,
        anchor: item.anchor,
        collapse: item.collapse,
        hideMenuIcon: item.hideMenuIcon,
        headerLevel: _vm.headerLevel,
        depth: item.depth
      },false)):_vm._e()]})],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }